import { BankTransaction } from "api/bank-accounts/models";
import { Employee } from "api/other/models";
import { Log } from "api/logbook/models";
import { OrderStageItem } from "api/order-stages/models";
import { BaseUser, UserWithInitialsExtendedDetails, UserWithInitials } from "api/users/models";
import { LoadingStatusPackage, PackageLocations } from "api/warehouse/models";
import { CommentType } from "components/common";
import {
  Channels,
  ColorPaletteBackground,
  ColorPaletteTextColor,
  CountryCode,
  CURRENCY_TYPE,
} from "CONSTANTS";
import { Assign } from "utility-types";
import { FabricAttributeKind, StandardAttributeKind } from "../attributes/models";
import { BasePaymentType, Decimal, ISODate, ISODateTime, ISOTime, UUID } from "api/types";
import { ShippingService } from "constants/shippingServiceConstants";
import { ScheduleConfirmationStatus } from "api/external-manufacturing/models";
import { InvoiceType, TradingDocumentType } from "api/trading-documents/models";
import { AttributesAsString } from "components/miloDesignSystem/molecules/attributes/types";
import { Customer } from "api/customers/models";
import { ManufacturingStatus as ManufacturingOrderStatus } from "api/routes/models";
import { Product } from "api/products/models";
import { DeliveryMethodChoices } from "api/common/enums";
import { WarehouseIconKind } from "api/wms/models";
import { OrderReleaseStatus, OrderTypeChoices, PriorityChoices, TestOrderTypes } from "./enums";
import { ColorPalette } from "components/miloDesignSystem/atoms/colorsPalette";
import { OrderTagBackgroundColor } from "api/order-tags/models";
import { ProcessingStatusChoices } from "api/marketplace-statuses/models";
import { StageStatus } from "api/manufacturing/units/models";

export type OrderPaymentSource = "ON_DELIVERY" | "ONLINE";

export type OrderPaymentType = "CASH_ON_DELIVERY" | "ONLINE" | "DEBIT_CARD" | "INSTALMENT";

export type OrderPaymentStatus = "UNPAID" | "PAID" | "PARTIALLY_PAID" | "OVERPAID";

export enum CreatePickingOrLoadingSource {
  ROUTE = "ROUTE",
  ORDER_GROUP = "ORDER_GROUP",
}

type Kind = "REPLACEMENT" | "REPAIR" | "SUPPLEMENT" | "RESIGNATION" | "INSPECTION";
type Direction = "TO_BE_PICKED_UP" | "TO_BE_DELIVERED";
type WarrantyStatus = "WITH_WARRANTY" | "WITHOUT_WARRANTY" | "";
export type PaymentProvider = "PAYU" | "P24";

export type Status =
  | "RECEIVED"
  | "PROCESSING"
  | "ROUTE_READY"
  | "ON_ROUTE"
  | "DELIVERED"
  | "SETTLED"
  | "CANCELED";

export interface Order {
  name: string;
  created: string;
  shipments: {
    id: UUID;
    shippingService: { id: number; provider: ShippingService; name: string } | null;
    signature: string;
    courierDeliveredDate: string | null;
    createExternalShipmentAt: ISODateTime | null;
  }[];
  tags: OrderTagDetails[];
  isInvoiceRequired: boolean;
  isSeen: boolean;
  isExternalRelease: boolean;
  createdBy: { id: number; firstName: string; lastName: string; avatar: string } | null;
  collectionHours: {
    collectionDate: string | null;
    maximumCollectionHour: string | null;
    minimumCollectionHour: string | null;
    plannedCollectionHour: string | null;
  };
  deliveryConfirmationDeadline: string | null;
  deliveryDateConfirmTimestamp: string | null;
  deliveryDateConfirmationSource: string;
  deliveryDateConfirmedBy: BaseUser;
  expressService: ExpressService | null;
  expressServiceExpireDate: ISODateTime | null;
  expressServiceHoursDelta: number | null;
  isCanceled: boolean;
  isExternal: boolean;
  isConfirmed: boolean;
  isSettled: boolean;
  signature: string;
  warehouseDeliveryDate: string | null;
  isInWarehouse: boolean;
  locatedInWarehouse: string;
  id: number;
  complaintPictures: ComplaintPicture[];
  parent: {
    created: string;
    signature: string;
    uuid: string;
    numberOfCreatedComplaints: number;
  } | null;
  uuid: string;
  receipt: OrderReceipt | null;
  shipmentTime: string;
  description: string;
  totalPrice: number;
  itemsPrice: number;
  numberOfPackages: number;
  isConfirmedInExternalService: boolean;
  isCollectionDateConfirmed: boolean;
  isDeliveryDateConfirm: boolean;
  hasTransportBeenInvoiced: boolean;
  hasItemsBeenInvoiced: boolean;
  hasTransportBeenSettled: boolean;
  hasItemsBeenSettled: boolean;
  hasExternalFulfillment: boolean;
  itemsInvoicedDate: string | null;
  maximumCollectionHourAtConfirmation: string;
  minimumCollectionHourAtConfirmation: string;
  transportInvoicedDate: string | null;
  itemsSettledDate: string | null;
  transportSettledDate: string | null;
  kind: Kind;
  direction: Direction;
  warrantyStatus: WarrantyStatus;
  numberOfDaysFromCreatedDate: number;
  hasCarrying: boolean;
  isAssemblingRequested: boolean;
  hasSmsSent: boolean;
  customer: {
    id: number;
    canAddNewIndexes: boolean;
    priceList: {
      color: string;
      currency: CURRENCY_TYPE;
      id: number;
      name: string;
      textColor: string;
    } | null;
    name: string;
    hasDropShipping: boolean;
    hasAmountToCollect: boolean;
  } | null;
  collectionDate: string | null;
  assignedTo: null | UserWithInitialsExtendedDetails;
  leftDays: number;
  hasGuaranteedDeliveryBeforeChristmas: boolean;
  items: OrderProductInstance[];
  comments: CommentType[];
  payment: {
    id: number;
    status: OrderPaymentStatus;
    source: OrderPaymentSource;
    type: OrderPaymentType;
    isVerificationRequired: boolean;
    isReturn: boolean;
    externalId: string;
    provider: PaymentProvider;
    discount: number | string;
    paidAmount: number | string;
    externalAmount: number | string;
    amountToCollect: number | string;
    currency: string;
    finishedAt: ISODateTime | null;
  };
  orderStages: OrderStageItem[];
  missingAmount: string;
  isTransportOrder: boolean;
  salesAccount: {
    channel: string;
    id: number;
    name: string;
    color: ColorPaletteBackground;
    textColor: ColorPaletteTextColor;
    currency: CURRENCY_TYPE;
  };
  invoice: {
    id: number;
    street: string;
    city: string;
    postCode: string;
    firstName: string;
    lastName: string;
    companyName: string;
    taxId: string;
    email: string | null;
  } | null;
  offers:
    | {
        id: number;
        itemId: number;
        externalId: string;
        url: string;
        isAssigned: boolean;
      }[]
    | null;
  miloOffers:
    | {
        id: number;
        itemId: number;
        externalId: string;
        url: string;
        isAssigned: boolean;
      }[]
    | null;

  messageToSeller: string;
  recentManufacturersOrders: ManufacturerOrder[];
  recentProductionOrders: ProductionOrder[];
  delivery: Delivery;
  isVisited: boolean;
  type: OrderTypeChoices;
  status: Status;
  statusTemp: StatusTemp;
  followers: { id: number; avatar: string; firstName: string; lastName: string }[];
  reservations: { id: number; signature: string; created: string }[];
  route: {
    id: number;
    signature: string;
    name: string;
    departureDate: string;
    departureTime: string;
    loadingDate: string;
    loadingTime: string;
    length: number;
    driver: {
      id: number;
      userId: number;
      firstName: string;
      lastName: string;
      avatar: string;
      phone: string;
    };
  } | null;
  onWhichRoutesItWas: {
    id: number;
    signature: string;
    departureDate: string;
    departureTime: string;
    driver: {
      id: number;
      avatar: string;
      userId: number;
      lastName: string;
      firstName: string;
    };
  }[];
  inWhichGroupsItWas: {
    id: number;
    signature: string;
    name: string;
    pickUpDate: string;
    pickUpTime: string;
  }[];
  orderGroup: {
    id: number;
    signature: string;
    name: string;
    pickUpDate: string;
    pickUpTime: string;
  };
  isCallRequired: boolean;
  isWaitingForCustomerCall: boolean;
  isShipped: boolean;
  recentLogs: Log[];
  hasProducedItems: boolean;
  totalVolume: number;
  totalWeight: number;
  totalHardWeight: number;
  totalSoftWeight: number;
  isMerged: boolean;
  isSynchronizedWithBaselinker: boolean;
  baselinkerId: number;
  priority: PriorityChoices;
  placedAt: ISODateTime;
  receivedInExternalService: ISODateTime;
  statusTrackingLink: string;
  statusTrackingToken: string;
  isDelivered: boolean;
  deliveredDate: string | null;
  dateOfDelivered: string | null;
  latestDeliveryDate: ISODateTime | null;
}

export interface OrderPatch {
  statusTemp?: number;
}

export interface OrderReceipt {
  externalId: string;
  id: number;
  isPrinted: boolean;
  issued: string | null;
  lastModified: ISODateTime | null;
  lastModifiedBy: BaseUser | null;
  lastPrinted: ISODateTime | null;
  lastPrintedBy: BaseUser | null;
  order: number;
}

export interface OrderPost {
  customer?: number | null;
  transactionId: string;
  salesAccount: number;
  route: number | null;
  isCanceled?: boolean;
  description?: string;
  name?: string;
  isExternalRelease?: boolean;
  assignedTo?: number | null;
  isSeen?: boolean;
  isShipped?: boolean;
  hasCarrying?: boolean;
  isSettled?: boolean;
  shipmentTime?: string;
  isTransportOrder?: boolean;
  collectionDate: string | null;
  payment: {
    type: OrderPaymentType;
    source: OrderPaymentSource;
    provider: string;
    status: "UNPAID" | "PAID" | "PARTIALLY_PAID" | "OVERPAID";
    discount: number;
    paidAmount: number;
    currency: string;
  };
  invoice?: {
    street: string;
    city: string;
    postCode: string;
    firstName: string;
    companyName: string;
    lastName: string;
    taxId: string;
    email?: string;
  } | null;
  delivery: {
    firstName: string;
    lastName: string;
    phone: string;
    street: string;
    city: string;
    countryCode: string;
    postCode: string;
    method: string;
    amount: string;
    companyName: string;
    clientLogin?: string;
    email?: string;
  };
  isInvoiceRequired: boolean;
  isConfirmed: boolean;
  messageToSeller: string;
  comments: {
    body: string;
  }[];
  items: {
    product: number;
    currency: string;
    index: number;
  }[];
  isCallRequired?: boolean;
  isWaitingForCustomerCall?: boolean;
  hasProducedItems?: boolean;
  priority?: string;
}

export interface Delivery {
  coordinates: { lat: number; lng: number };
  minimumDeliveryDate: string | null;
  minimumDeliveryTime: string;
  maximumDeliveryTime: string;
  plannedDeliveryTime: string | null;
  companyName: string;
  countryCode?: string;
  customer: number | null;
  firstName: string;
  lastName: string;
  postCode: string;
  currency: string;
  method: DeliveryMethodChoices;
  street: string;
  pickupCode: string;
  deliveryPoint: { lat: number; lng: number } | null;
  pickupCodeUsedOnDelivery: string;
  amount: string;
  phone: string;
  city: string;
  id: number;
  clientLogin?: string;
  shippingService: number | null;
  shippingServiceDisplay: string | null;
  email: null | string;
}

export interface CommentPost {
  body: string;
  isOnlyVisibleForTeam?: boolean;
  order: number | string;
}

export interface OrderCategory {
  count: number;
  id: number;
  name: string;
}

export interface OrderPoint {
  countryName: string;
  expressService: ExpressService | null;
  expressServiceExpireDate: ISODateTime | null;
  expressServiceHoursDelta: number | null;
  hasCarrying: boolean;
  hasGuaranteedDeliveryBeforeChristmas: boolean;
  hasProducedItems: boolean;
  hasUpholstery: boolean;
  hideOnMapTo: string | null;
  hideUntilIssueIsSolved: boolean;
  id: number;
  isAssemblingRequested: boolean;
  isHidden: boolean;
  leftDays: number;
  numberOfDaysFromCreatedDate: number;
  paymentType: Order["payment"]["type"];
  point: {
    lat: number;
    lng: number;
  };
  priority: PriorityChoices;
  type: OrderTypeChoices;
  warehouse: { id: number; icon: WarehouseIconKind };
  warehouseDeliveryDetails: WarehouseDeliveryDetails;
}

export interface WarehouseDeliveryDetails {
  daysLeftToDelivery: number | null;
  isInWarehouse: boolean;
  date: string | null;
}

export interface GroupOrderPoint {
  isHidden: boolean;
  hideOnMapTo: string | null;
  hideUntilIssueIsSolved: boolean;
  hasGuaranteedDeliveryBeforeChristmas: boolean;
  id: number;
  type: OrderTypeChoices;
  point: {
    lng: number;
    lat: number;
  };
  leftDays: number;
  numberOfDaysFromCreatedDate: number;
  hasUpholstery: boolean;
  method: DeliveryMethodChoices;
  shippingService: null | string;
  priority: PriorityChoices;
  warehouseDeliveryDetails: null;
  warehouse: { id: number; icon: WarehouseIconKind };
}

export enum SimplOrderPaymentSource {
  ON_DELIVERY = "ON_DELIVERY",
  ONLINE = "ONLINE",
}

export interface SimpleListOrder extends ListOrder {
  paymentSource: SimplOrderPaymentSource;
  paymentType: OrderPaymentType;
  tradingDocuments: {
    canBeFiscalized: boolean;
    id: UUID;
    signature: string;
    type: TradingDocumentType;
    invoiceType: InvoiceType;
  }[];
}

export interface OrderTagDetails {
  id: UUID;
  tag: {
    backgroundColor: OrderTagBackgroundColor;
    id: UUID;
    name: string;
    textColor: ColorPalette;
  };
}

export enum CustomerConfirmationStatus {
  CONFIRMED = "CONFIRMED",
  DECLINED = "DECLINED",
  NOT_SENT = "NOT_SENT",
  PENDING = "PENDING",
}

export enum SmsStatus {
  ERROR = "ERROR",
  NOT_SEEN = "NOT_SEEN",
  NOT_SENT = "NOT_SENT",
  SEEN = "SEEN",
  SENT = "SENT",
}

export enum ReservationStatus {
  ALL_RESERVED = "ALL_RESERVED",
  NOTHING_RESERVED = "NOTHING_RESERVED",
  RELEASED = "RELEASED",
  SOME_RESERVED = "SOME_RESERVED",
}

export interface ListOrder {
  isConfirmed: boolean;
  shipments: {
    id: UUID;
    shippingService: { id: number; provider: ShippingService; name: string } | null;
    signature: string;
    courierDeliveredDate: string | null;
    createExternalShipmentAt: ISODateTime | null;
  }[];
  isCollectionDateConfirmed: boolean;
  deliveryAmount: Decimal;
  isInvoiceRequired: boolean;
  isDeliveryDateConfirm: boolean;
  hasTransportBeenInvoiced: boolean;
  customerConfirmationStatus: CustomerConfirmationStatus;
  kind: Kind;
  items: {
    amount: Decimal;
    quantity: number;
  }[];
  receipt: {
    externalId: string;
    id: number;
    isPrinted: boolean;
    issued: string | null;
    lastModified: string | null;
    lastModifiedBy: BaseUser | null;
    lastPrinted: string | null;
    lastPrintedBy: BaseUser | null;
    order: number;
  } | null;
  direction: Direction;
  expressService: ExpressService | null;
  expressServiceExpireDate: ISODateTime | null;
  expressServiceHoursDelta: number | null;
  warrantyStatus: WarrantyStatus;
  hasItemsBeenInvoiced: boolean;
  numberOfDaysFromCreatedDate: number;
  smsStatus: SmsStatus;
  uuid: string;
  name: string;
  hasTransportBeenSettled: boolean;
  hasItemsBeenSettled: boolean;
  itemsSettledDate: string | null;
  transportSettledDate: string | null;
  itemsInvoicedDate: string | null;
  transportInvoicedDate: string | null;
  isShipped: boolean;
  dateOfDelivered: string | null;
  deliveredDate: ISODateTime | null;
  messageToSeller: string;
  isSettled: boolean;
  signature: string;
  isSeen: boolean;
  isDelivered: boolean;
  route: {
    id: number;
    signature: string;
    departureDate: ISODate;
    departureTime: ISOTime;
  } | null;
  orderGroup: {
    id: number;
    signature: string;
  } | null;
  hasSmsSent: boolean;
  smsSentTimestamp: string | null;
  deliveryDateConfirmTimestamp: string | null;
  id: number;
  customer: {
    id: number;
    name: string;
    textColor: string;
    color: string;
    hasDropShipping: boolean;
  } | null;
  isConfirmedInExternalService: boolean;
  tags: OrderTagDetails[];
  manufacturingOrders: {
    createdAt: ISODateTime;
    id: UUID;
    signature: string;
  }[];
  externalManufacturingOrders: {
    createdAt: ISODateTime;
    id: UUID;
    signature: string;
  }[];
  assignedTo: UserWithInitialsExtendedDetails | null;
  deliveryAddress: {
    clientLogin?: string;
    countryCode?: string | null;
    city: string;
    postCode: string;
    street: string;
    phone: string;
  };
  type: OrderTypeChoices;
  status: Status;
  deliveryMethod: DeliveryMethodChoices;
  indexes: { quantity: number; name: string; note: string }[];
  statusTemp: StatusTemp;
  hasGuaranteedDeliveryBeforeChristmas: boolean;
  payment: Order["payment"];
  salesAccount: {
    channel: string;
    id: number;
    name: string;
    color: ColorPaletteBackground;
    textColor: ColorPaletteTextColor;
    currency: CURRENCY_TYPE;
  };
  reservationStatus: ReservationStatus;
  client: {
    firstName: string;
    lastName: string;
    phone: string;
    companyName: string;
    hasDropShipping: boolean;
  };
  created: ISODateTime;
  placedAt: ISODateTime;
  leftDays: number;
  isCallRequired: boolean;
  isWaitingForCustomerCall: boolean;
  manufacturingStatus: {
    finished: number;
    requested: number;
    total: number;
  } | null;
}

export interface OrderProductInstanceAttribute {
  id: number;
  name: string;
  description: string;
  kind: StandardAttributeKind;
  values: {
    isAssignableToIndex: boolean;
    picture: string | null;
    name: string;
    id: number;
  }[];
}

export interface PostOrderCopyDTO {
  orders: {
    id: number;
    copyWithItem: boolean;
  }[];
}

export interface OrderProductInstanceFabricAttribute {
  id: number;
  name: string;
  description: string;
  kind: FabricAttributeKind;
  categories: {
    name: string;
    values: {
      picture: string | null;
      isAssignableToIndex: boolean;
      name: string;
      id: number;
    }[];
  }[];
}

export interface OrderProductInstance {
  amount: Decimal;
  boughtAt: ISODateTime | null;
  carryingUnitAmount: string | null;
  constantValues: number[];
  currency: CURRENCY_TYPE;
  deliveryUnitAmount: string;
  deliveryUnitAmountDiscount: string;
  ean: string;
  externalNumber: string;
  externalSku: string;
  id: number;
  index: number;
  indexAttributes: AttributesAsString;
  isAddedToComplaint: boolean;
  isExcludedFromReservation: boolean;
  isPrepared: boolean;
  isReleasedFromStock: boolean;
  isReserved: boolean;
  maxComplaintQuantity: number;
  name: string;
  note: string;
  numberOfPreparedUpholsteries: number;
  offer: {
    externalId: string;
    id: number;
    name: string;
  };
  order: number;
  packages: {
    id: number;
    internalId: string;
    isAddedToComplaint: boolean;
    name: string;
    package: number;
    quantity: number;
  }[];
  product: {
    id: number;
    ean: string;
    kind: Product["kind"];
    name: string;
    picture: string;
    attributes: (OrderProductInstanceAttribute | OrderProductInstanceFabricAttribute)[];
    isAddedToComplaint: null;
    category: number | null;
  };
  productSetCode: string;
  productSetName: string;
  quantity: number;
  unitAmount: string | null;
  weight: number;
}

export interface UpholsteryOrder {
  id: number;
  salesAccount: {
    id: number;
    name: string;
    channel: string;
    color: ColorPaletteBackground;
    textColor: ColorPaletteTextColor;
    currency: CURRENCY_TYPE;
  };
  leftDays: number;
  signature: string;
  priority: "low";
  created: string;
  productionOrders: {
    upholsterer: Employee | null;
    seamstress: Employee | null;
    cutter: Employee | null;
    signature: string;
    id: number;
    name: string;
    delegations: {
      isCut: boolean;
      isSewn: boolean;
      isPrepared: boolean;
    };
    attributes: {
      attribute: string;
      value: string;
    }[];
    comments: {
      id: number;
      body: string;
      user: {
        id: number;
        avatar: string;
        firstName: string;
        lastName: string;
        phone: string;
      };
      created: string;
    }[];
    isSendToManufacturer: boolean;
    isReceivedFromManufacturer: boolean;
    isReadyToCollectFromManufacturer: boolean;
  }[];
  client: {
    firstName: string;
    lastName: string;
    phone: string;
    companyName: string;
  };
  deliveryAddress: {
    clientLogin?: string;
    postCode: string;
    city: string;
    street: string;
  };
  isReceivedFromManufacturer: boolean;
  isSendToManufacturer: boolean;
}

export interface SplittedOrder {
  id: number;
  signature: string;
  price: number;
  weight: number;
  productCount: number;
  itemsCount: number;
  items: {
    index: number;
    quantity: number;
    amount: number;
    currency: string;
    boughtAt: string | null;
    id: number;
    product: { id: number; picture: string; name: string };
  }[];
}
export type SplittedOrders = [SplittedOrder, SplittedOrder];

export interface OrderCollectionDateConfirmation {
  collectionDate: string | null;
  plannedCollectionHour: string | null;
  deliveryConfirmationDeadline: string | null;
  minimumCollectionHour: string | null;
  maximumCollectionHour: string | null;
  phone: string | null;
  phoneDe: string;
  email: string | null;
  hasCarrying: boolean;
  officeOpeningHours: string;
  language: "pl" | "de" | "at" | "nl";
  order: {
    items: {
      id: number;
      quantity: number;
      amount: number;
      product: { id: number; name: string };
    }[];
    signature: string;
    customer: Order["customer"];
    payment: {
      paidAmount: number;
      amountToCollect: number;
      discount: string | number;
      currency: string;
    };
    delivery: {
      firstName: string;
      amount: number;
      lastName: string;
      phone: string;
      street: string;
      city: string;
      postCode: string;
      email: string;
    };
    numberOfPackages: number;
    totalWeight: number;
  };
}
export interface OrderTrackingInfo {
  car: {
    coordinates: {
      lat: number;
      lng: number;
    };
  } | null;
  order: {
    status: Order["status"];
    statusText: string;
    plannedDeliveryTime: string;
    coordinates: {
      lng: number;
      lat: number;
    };
  };
  contact: {
    firstName: string;
    lastName: string;
    phone: string;
  };
}
export interface OrderForRoute {
  id: number;
  hasGuaranteedDeliveryBeforeChristmas: boolean;
  created: string;
  delivery: {
    id: number;
    postCode: string;
    isHidden: boolean;
    hideIssue: string;
    hideOnMapTo: string | null;
    hideUntilIssueIsSolved: boolean;
    city: string;
    street: string;
    price: { amount: number; currency: CURRENCY_TYPE };
    point: { lng: number; lat: number };
    distance: number | null;
    time: number | null;
    plannedDeliveryTime: string | null;
    shippingService: string | null;
    stopoverTime: number;
    countryName: string;
    amount: number;
    companyName: string;
    firstName: string;
    lastName: string;
    maximumDeliveryTime: string | null;
    minimumDeliveryTime: string | null;
    minimumCollectionHour: string | null;
    maximumCollectionHour: string | null;
    minimumCustomDeliveryHour: string | null;
    maximumCustomDeliveryHour: string | null;
    phone: string;
  };
  expressService: ExpressService | null;
  expressServiceExpireDate: ISODateTime | null;
  expressServiceHoursDelta: number;
  customer: Order["customer"];
  signature: string;
  comments: Order["comments"];
  items: {
    id: number;
    index: number;
    amount: number;
    quantity: number;
    product: { id: number; category: number; name: string };
    attributes: { attribute: string; value: string }[];
  }[];
  leftDays: number;
  numberOfDaysFromCreatedDate: number;
  type: Order["type"];
  totalVolume: Order["totalVolume"];
  totalWeight: Order["totalWeight"];
  totalHardWeight: Order["totalHardWeight"];
  totalSoftWeight: Order["totalSoftWeight"];
  hasProducedItems: boolean;
  hasCarrying: boolean;
  hasSmsSent: boolean;
  hasEmailSent: boolean;
  isAssemblingRequested: boolean;
  deliveryDateRejectedByAvatar: string | null;
  deliveryDateRejectedByFirstName: string;
  deliveryDateRejectedByLastName: string;
  maximumCollectionHourAtConfirmation?: string;
  minimumCollectionHourAtConfirmation?: string;
  deliveryDateRejectedTimestamp: string | null;
  isDeliveryDateConfirm: boolean;
  isDeliveryDateRejected: boolean;
  collectionHours: Order["collectionHours"];
  messageToSeller: string;
  hasReservations: boolean;
  totalItemsCarryingAmount: number;
  totalItemsTransportAmount: number;
  currency: CURRENCY_TYPE;
  hasUpholstery: boolean;
  isSettled: boolean;
  isCanceled: boolean;
  prices: { PLN: number };
  client: {
    firstName: string;
    lastName: string;
    phone: string;
    companyName: string;
    email: string;
    hasDropShipping: boolean;
  };
  payment: {
    id: number;
    discount: number;
    paidAmount: number;
    status: OrderPaymentStatus;
    type: OrderPaymentType;
  };
  isConfirmed: boolean;
  method: DeliveryMethodChoices;
  phone: string;
  priority: Order["priority"];
  externalId: string | null;
  warehouseDeliveryDetails: WarehouseDeliveryDetails;
  warehouse: { id: number; icon: WarehouseIconKind };
}

export interface ManufacturerOrder {
  created: string;
  createdFor: string;
  id: number;
  isSentToManufacturer: boolean;
  isFinished: boolean;
  link: string;
  manufacturer: { name: string };
  internalId: string;
  sent: number;
  isConfirmed: boolean;
  signature: string;
  total: number;
}

export interface ProductionOrder {
  created: string;
  createdFor: string;
  id: number;
  internalId: string;
  isFinished: boolean;
  name: string;
  signature: string;
}

export interface OrderIndex {
  attributes: {
    attribute: string;
    value: string;
  }[];
  created: ISODateTime;
  id: number;
  externalNumber: string;
  indexId: number;
  isSelfProduced: boolean;
  isSent: boolean;
  manufacturer: {
    id: number;
    name: string;
  } | null;
  manufacturingSchema: {
    id: UUID;
    name: string;
    signature: string;
  } | null;
  name: string;
  orderId: number;
  orderSignature: string;
}

export interface DailyStatistics {
  id: number;
  name: string;
  routeCount: number;
  orderGroupCount: number;
}

export type OrdersIndexPopularity = Record<
  string,
  {
    indexId: number;
    indexQuantity: number;
    indexSku: string;
    indexVerboseAttributesValues: string;
    productId: number;
    productCategoryId: number;
    productName: string;
    manufacturer: { id: number; name: string } | null;
  }[]
>;

export interface PostReservationResponse {
  errors: Record<number, number>;
  reservations: { id: number; signature: string; created: string }[];
}

export interface OrderPointsForRoute {
  results: OrderPoint[];
  categories: OrderCategory[];
  count: number;
}

export interface OrderItemsSearch {
  id: number;
  name: string;
  signature: string;
  delivery: {
    id: number;
    street: string;
    city: string;
    postCode: string;
    point: { lat: number; lng: number };
    firstName: string;
    lastName: string;
  };
  route: { id: number; signature: string; color: string } | null;
  customer: {
    color: string;
    hasDropShipping: boolean;
    id: number;
    name: string;
    textColor: string;
  } | null;
  externalId: string | null;
}

export interface OrderBankTransactionsSearch {
  client: {
    firstName: string;
    lastName: string;
    street: string;
    city: string;
    postCode: string;
    phone: string;
  };
  currency: CURRENCY_TYPE;
  id: number;
  isAdded: boolean;
  signature: string;
  totalAmount: string;
  transactionsExternalIds: string[];
}

export interface OrderNearestRoutes {
  routes: {
    id: number;
    signature: string;
    name: string;
    driver: {
      id: number;
      userId: number;
      firstName: string;
      lastName: string;
      avatar: string | null;
      phone: string;
    } | null;
    departureDate: string | null;
    departureTime: string | null;
    loadingDate: string | null;
    loadingTime: string | null;
    length: number;
  }[];
}

export interface OrderAdditionalData {
  id: number;
  uuid: string;
  signature: string;
  joinedOrders:
    | {
        id: number;
        uuid: string;
        signature: string;
        created: string;
        assignedTo: null | { avatar: string | null; firstName: string; lastName: string };
      }[]
    | null;
}

export interface JoinedOrder {
  id: number;
  signature: string;
  uuid: string;
  name: string;
  customer: { id: number; name: string; hasAmountToCollect: boolean } | null;
  priceList: {
    color: string;
    currency: CURRENCY_TYPE;
    id: number;
    name: string;
    textColor: string;
  } | null;
  salesAccount: string | null;
  assignedTo: {
    id: number;
    avatar: string | null;
    firstName: string;
    lastName: string;
    phone: string;
    isActive: boolean;
    isSuperuser: boolean;
  };
  isMerged: boolean;
  delivery: {
    id: number;
    companyName: string;
    firstName: string;
    lastName: string;
    phone: string;
    street: string;
    city: string;
    postCode: string;
    amount: number | string;
    currency: CURRENCY_TYPE;
    clientLogin: string;
    email: string;
    method: Order["delivery"]["method"];
  };
  payment: {
    id: number;
    type: OrderPaymentType;
    status: Order["payment"]["status"];
    source: OrderPaymentSource;
    paidAmount: number | string;
    discount: number | string;
    currency: CURRENCY_TYPE;
    amountToCollect: number | string;
  };
  items: {
    amount: number | string;
    attributesValues: string;
    currency: CURRENCY_TYPE;
    id: number;
    name: string;
    note: string;
    quantity: number;
  }[];
  messageToSeller: string;
  comments: {
    body: string;
    created: string;
    id: number;
    user: {
      avatar: string | null;
      firstName: string;
      id: number;
      isActive: boolean;
      isSuperuser: boolean;
      lastName: string;
      phone: string;
    };
  }[];
}
export interface ComplaintParent {
  parent: {
    signature: string;
    id: number;
    uuid: string;
    numberOfComplaints: number;
    created: string;
    otherComplaints: {
      signature: string;
      id: number;
      uuid: string;
      isCanceled: boolean;
      isSettled: boolean;
      createdBy: {
        id: number;
        avatar: string | null;
        firstName: string;
        lastName: string;
        phone: string;
        isActive: boolean;
        isSuperuser: boolean;
      };
      created: string;
    }[];
  };
}
export interface ComplaintPicture {
  complaint: number;
  description: string;
  file: string;
  id: number;
  name: string;
}

export interface ComplaintPost {
  parent: number | null;
  reason: string;
  direction: string;
  kind: Kind;
  warrantyStatus: WarrantyStatus;
  description?: string;
  customer?: number;
  salesAccountId?: number | null;
  items: {
    id: number;
    quantity: number;
    productId: number;
    maxComplaintQuantity: number;
    isAddedToComplaint: boolean;
    indexId: number;
    note: string;
  }[];
  delivery?: {
    firstName: string;
    externalAddressNumber: string;
    lastName: string;
    country: string;
    phone: string;
    email: string;
    street: string;
    city: string;
    postCode: string;
    point: { lat: number; lng: number } | null;
    companyName: string;
  };
}

export interface PostDevelopmentOrdersSchema {
  numberOfOrders: number;
  items: {
    minRangeNumber: number;
    maxRangeNumber: number;
    minPrice: string;
    maxPrice: string;
    minQuantity: number;
    maxQuantity: number;
    includeSets: boolean;
  };
  orderTypes: TestOrderTypes[];
  marketplaces: {
    minNumberOfOrders: number;
    maxNumberOfOrders: number;
    channels: Channels[];
  };
  customers: {
    minNumberOfOrders: number;
    maxNumberOfOrders: number;
  };
  dropshipping: {
    minNumberOfOrders: number;
    maxNumberOfOrders: number;
  };
  deliveries: {
    minPrice: string;
    maxPrice: string;
  };
  payments: {
    maximumDiscount: string;
    types: OrderPaymentType[];
    selectedCurrencies: CURRENCY_TYPE[];
  };
  whichOfficeEmployees: string[];
}
type UserPartial = {
  id: number;
  avatar: string | null;
  firstName: string;
  lastName: string;
} | null;

export interface OrderMessagesSchema {
  order: { id: number; signature: string; salesAccount: number };
  client: { firstName: string; lastName: string; avatar: string | null };
  messages: {
    id: number | string;
    status: "SENT" | "AWAITING" | "DELIVERED" | "UNSENT" | "UNDELIVERED";
    timestamp: string | null;
    body: string;
    wayOfCommunication: "OUTGOING" | "INCOMING";
    isRead: boolean;
    kind: "NORMAL" | "REQUEST_FOR_CUSTOMER_CONFIRMATION";
    readAt: string | null;
    readBy: UserPartial;
    createdBy: Assign<UserWithInitials, { initials: string; phone: string }>;
    sentToPhoneNumber: string;
    failureReason: string;
    created: string;
  }[];
}

export type Point = { lat: number; lng: number };

export interface OrderDeliveryInfo {
  items: {
    id: number;
    isDelivered: boolean;
    point: Point;
    distanceToDeliveryPoint: number;
  }[];
  packages: {
    description: string;
    id: number;
    internalId: string;
    isScanned: boolean;
    name: string;
    outOfScan: boolean;
    uniquePackage: { code: string } | null;
  }[];
  notDeliveredReasons: {
    id: number;
    createdBy: { id: number; firstName: string; lastName: string };
    body: string;
    createdAt: ISODateTime;
  }[];
  driverNotes: {
    body: string;
    createdBy: { id: number; firstName: string; lastName: string };
    duration: number;
    file: string;
    id: number;
    title: string;
  }[];
  signatures: {
    id: number;
    createdBy: { id: number; firstName: string; lastName: string };
    point: Point;
    distanceToDeliveryPoint: number;
    url: string;
    createdAt: string;
  }[];
  photos: {
    id: string;
    url: string;
    point: Point;
    distanceToDeliveryPoint: number;
    createdAt: string;
    createdBy: { id: number; firstName: string; lastName: string };
  }[];
}

export interface OrderTransactions {
  id: number;
  signature: string;
  paymentDate: string | null;
  client: {
    firstName: string;
    lastName: string;
    phone: string;
    address: { street: string; postCode: string; city: string };
  };
  bankTransactions: Assign<
    BankTransaction,
    {
      addedBySource: "MANUALLY" | "IMPORTED" | "FETCHED_FROM_API";
      created: string;
      relationId: number;
      createdBy: {
        avatar: string;
        firstName: string;
        id: number;
        lastName: string;
      };
    }
  >[];
}

export interface Listings {
  filledListings: {
    listing: {
      name: string;
      attributes: string;
      externalId: string;
      ean: string;
      quantity: number;
      amount: string;
      currency: string;
    };
    items: {
      name: string;
      attributes: string;
      quantity: number;
      amount: number;
      currency: string;
      externalId: string;
      ean: string;
    }[];
  }[];
  emptyListings: {
    name: string;
    attributes: string;
    externalId: string;
    ean: string;
    quantity: number;
    amount: string;
    currency: string;
  }[];
  itemsWithoutListings: {
    name: string;
    attributes: string;
    quantity: number;
    amount: number;
    currency: string;
    externalId: string;
    ean: string;
  }[];
  salesAccount: {
    channel: Channels;
    color: string;
    currency: string;
    id: number;
    name: string;
    textColor: string;
  };
}

export interface LoadingStatus {
  hasLoadingCompleted: boolean;
  items: {
    id: number;
    product: {
      id: number;
      picture: string | null;
      name: string;
      category: string | null;
    };
    index: number;
    quantity: number;
    amount: number;
    currency: string;
    boughtAt: string | null;
    packages: LoadingStatusPackage[];
  }[];
}

export interface OrderStatusBulkUpdateDTO {
  orders: number[];
  isDelivered: boolean;
  isVisited: boolean;
}

export interface StatusTemp {
  color: ColorPalette;
  icon: string | null;
  id: number;
  meta: {};
  name: string;
  position: number;
  textColor: ColorPalette;
}

export interface OrderStatusTempBulkUpdateDTO {
  orders: number[];
  statusTemp: number;
}

export interface B2bWarehouseDeliveryDateBulkUpdate {
  objects: number[];
  warehouseDeliveryDate: string;
}

export interface OrderIsInWarehouseBulkUpdate {
  warehouseDeliveryDate: string | null;
  isInWarehouse: boolean;
  orders: number[];
}

export interface UpdateCustomerBulkUpdate {
  customer: number | null;
  orders: number[];
}

enum LocationType {
  "RAMP" = "RAMP",
  "WAREHOUSE" = "WAREHOUSE",
}

export enum ManufacturingStatus {
  NOT_SCHEDULED = "NOT_SCHEDULED",
  AWAITING = "AWAITING",
  IN_PROGRESS = "IN_PROGRESS",
  FINISHED = "FINISHED",
}

export enum WarehouseStatus {
  IN_STOCK = "IN_STOCK",
  OUT_OF_STOCK = "OUT_OF_STOCK",
}

export enum WarehouseReceivedStatus {
  NOT_RECEIVED = "NOT_RECEIVED",
  RECEIVED = "RECEIVED",
}

export enum WarehouseReleasedStatus {
  NOT_RELEASED = "NOT_RELEASED",
  RELEASED = "RELEASED",
}

enum ReturnStatus {
  NOT_RETURNED = "NOT_RETURNED",
  RETURNED = "RETURNED",
}

export enum DeliveryStatus {
  ON_ROUTE = "ON_ROUTE",
  NOT_DELIVERED = "NOT_DELIVERED",
  DELIVERED = "DELIVERED",
}

export enum ManufacturingItemStatus {
  DONE = "DONE",
  IN_PROGRESS = "IN_PROGRESS",
  PENDING = "PENDING",
}

export interface SingleItemQuantitiesForRoute {
  externalManufacturingInfo: {
    pickupBySellerPlannedAt: string | null;
    scheduleConfirmationStatus: ScheduleConfirmationStatus;
  };
  id: number;
  index: {
    attributes: {
      id: number;
      attribute: string;
      value: string;
    }[];
    id: number;
    product: {
      id: number;
      name: string;
      category: string;
    };
  };
  labelDownloadedAt: string | null;
  labelDownloadedBy: UserWithInitials | null;
  manufacturingItem: {
    id: UUID;
    status: ManufacturingItemStatus;
  } | null;
  order: {
    delivery: {
      point: {
        lat: number;
        lng: number;
      };
    };
    id: number;
    signature: string;
  };
  routePosition: string;
  statuses: {
    manufacturing: ManufacturingStatus;
    warehouseReceived: WarehouseReceivedStatus;
    warehouseReleased: WarehouseReleasedStatus;
  };
}

export interface SingleItemQuantities {
  id: UUID;
  index: {
    id: number;
    product: {
      id: number;
      name: string;
      category: string;
    };
    attributes: {
      id: number;
      attribute: string;
      value: string;
    }[];
  };
  item: {
    id: number;
    quantity: number;
  };
  labelDownloadedAt: string | null;
  labelDownloadedBy: string | null;
  position: number;
  statuses: QuantityStatuses;
}

export interface SingleItemQuantitiesDetails {
  id: UUID;
  statuses: QuantityStatuses;
  item: {
    id: number;
    quantity: number;
  };
  index: {
    id: number;
    product: {
      id: number;
      name: string;
      category: string;
    };
    attributes: {
      id: number | string;
      attribute: string;
      value: string;
    }[];
  };
  labelDownloadedAt: string | null;
  packages: {
    id: string;
    package: {
      id: number;
      name: string;
      description: string;
      internalId: string;
    };
    code: string;
    location: Assign<
      Omit<PackageLocations["locations"][number], "path" | "warehouseId" | "warehouse" | "code">,
      {
        type: LocationType;
        name: string;
      }
    >;
    statuses: QuantityStatuses;
    uniquePackage: {
      id: number;
      code: string;
    } | null;
    warehouse: {
      id: number;
      name: string;
    };
  }[];
  labelDownloadedBy: string | null;
  position: number;
  locations: Assign<
    Omit<PackageLocations["locations"][number], "path" | "warehouseId" | "warehouse" | "code">,
    {
      type: LocationType;
      name: string;
    }
  >[];
}

export type QuantityStatuses = {
  manufacturing: {
    value: ManufacturingStatus;
    requested: number;
    completed: number;
  };
  warehouse: {
    value: WarehouseStatus;
  };
  warehouseReceived: {
    value: WarehouseReceivedStatus;
    requested: number;
    completed: number;
  };
  warehouseReleased: {
    value: WarehouseReleasedStatus;
    requested: number;
    completed: number;
  };
  return: {
    value: ReturnStatus;
    requested: number;
    completed: number;
  };
  delivery: {
    value: DeliveryStatus;
    requested: number;
    completed: number;
  };
};

export interface ItemsWithSingleQuantities {
  customMadeLabelDownloadedAt: string | null;
  customMadeLabelDownloadedBy: null;
  id: number;
  index: {
    id: number;
    product: {
      id: number;
      name: string;
      category: string;
    };
    attributes: {
      id: number;
      attribute: string;
      value: string;
    }[];
  };
  quantity: number;
  statuses: QuantityStatuses;
  locations: Assign<
    Omit<PackageLocations["locations"][number], "path" | "warehouseId" | "warehouse" | "code">,
    {
      type: LocationType;
      name: string;
    }
  >[];
}

export interface CreatePickingOrLoading {
  id: string | number;
  signature: string;
  kind: CreatePickingOrLoadingSource;
}

export interface OrderWarehouseLocation {
  id: number;
  signature: string;
  name: string;
  externalId: string;
}

export interface OrderSingleItemQuantityWarehouseLocation {
  id: UUID;
  packages: {
    id: UUID;
    name: string;
    location: string | null;
    position: number;
  }[];
}

enum SingleItemQuantityWarehouseReceivedStatus {
  NOT_RECEIVED = "NOT_RECEIVED",
  RECEIVED = "RECEIVED",
}

export interface FreeSingleItemQuantitiesListItem {
  id: UUID;
  name: string;
  order: {
    id: number;
    signature: string;
  } | null;
  index: {
    id: number;
    product: {
      id: number;
      name: string;
    };
    attributes: AttributesAsString;
  };
  packagesCount: number;
  customer: Pick<
    Customer,
    | "id"
    | "name"
    | "hasDropShipping"
    | "hasAmountToCollect"
    | "canAddNewIndexes"
    | "color"
    | "textColor"
  > | null;
  warehouseReceivedStatus: SingleItemQuantityWarehouseReceivedStatus;
  warehouseReceivedAt: ISODateTime | null;
}

export interface FreeSingleItemQuantitiesDetails
  extends Omit<FreeSingleItemQuantitiesListItem, "packagesCount"> {
  packages: {
    id: UUID;
    location: string;
    name: string;
    setAsReceivedAt: ISODateTime | null;
    description: AttributesAsString | string;
  }[];
}

export interface OrderForInvoicingPosition {
  amountWithTax: string;
  indexId: string;
  name: string;
  quantity: string;
  sourceId: string;
  vatRate: string;
}

export type RouteForInvoicingPosition = Assign<
  OrderForInvoicingPosition,
  { orderId: string | null }
>;

export interface OrderForInvoicing {
  created: ISODateTime;
  isInvoiced: boolean;
  customer: {
    canAddNewIndexes: boolean;
    color: string;
    hasAmountToCollect: boolean;
    hasDropShipping: boolean;
    id: number;
    name: string;
    textColor: string;
  } | null;
  id: number;
  invoices: {
    id: UUID;
    recipient: {
      firstName: string;
      lastName: string;
    };
    signature: string;
  }[];
  payment: {
    currency: CURRENCY_TYPE;
    provider: string;
    status: OrderPaymentStatus;
    type: BasePaymentType;
  };
  positions: OrderForInvoicingPosition[];
  salesAccount: Order["salesAccount"];
  signature: string;
  status: Status;
  type: OrderTypeChoices;
}

export interface ManufacturingOrder {
  id: number;
  signature: string;
  manufacturingStatus: ManufacturingOrderStatus;
  delivery: {
    firstName: string;
    companyName: string;
    lastName: string;
    phone: string;
    address: { street: string; postCode: string; city: string; countryCode: CountryCode };
  };
  customer: {
    id: number;
    canAddNewIndexes: boolean;
    name: string;
    hasDropShipping: boolean;
    hasAmountToCollect: boolean;
  } | null;
}

export interface OrdersGroupByInfo {
  group: {
    id: number;
    signature: string;
    type: CreatePickingOrLoadingSource;
  } | null;
  id: number;
}

export interface ManufacturingOrderDetails {
  delivery: {
    city: string;
    countryCode: CountryCode;
    firstName: string;
    id: number;
    lastName: string;
    phone: string;
    postCode: string;
    street: string;
  };
  id: number;
  invoice: {
    id: UUID;
    taxId: string;
  } | null;
  name: string;
  salesAccount: {
    id: number;
    name: string;
  } | null;
  signature: string;
}

export interface OrdersSalesAccountInfo {
  id: number;
  salesAccount: {
    channel: Channels;
    id: number;
    name: string;
  } | null;
}

export interface ItemProgress {
  status: StageStatus;
  finishedAt: ISODateTime | null;
  employee: UserWithInitials | null;
  manufacturer: {
    id: number;
    name: string;
  } | null;
}

export interface ManufacturingItemProgress {
  id: number;
  signature: string;
  order: {
    id: number;
    signature: string;
    messageToSeller: string;
    customer: {
      fullName: string;
      name: string;
    };
    delivery: {
      firstName: string;
      lastName: string;
    };
  } | null;
  manufacturingItem: Record<UUID, ItemProgress> | null;
  item: { id: number; externalNumber: string; note: string };
  index: {
    id: number;
    product: {
      id: number;
      name: string;
      category: string | null;
    };
    attributes: {
      id: number;
      attribute: string;
      value: string;
    }[];
  };
}

export interface OrderAttachment {
  id: UUID;
  order: UUID;
  file: string;
  createdAt: ISODateTime;
}

export interface AddOrdersToUnloadingResponse {
  addedToUnloading: {
    externalId: string;
    id: number;
    signature: string;
  }[];
  createdUnloading: {
    id: UUID;
    signature: string;
  } | null;
  inDifferentUnloading: {
    order: { id: number; signature: string; externalId: string };
    unloading: { id: UUID; signature: string } | null;
  }[];
  missingIndexes: { id: number; signature: string; externalId: string }[];
  missingOrders: { id: number; signature: string; externalId: string }[];
  missingPackages: { id: number; signature: string; externalId: string }[];
}

export interface CreateOrderGroupResponse {
  ordersToAdd: ListOrder[];
  createdOrderGroup: {
    id: number;
    signature: string;
  } | null;
  ordersOnRoute: {
    id: number;
    signature: string;
  }[];
  ordersOnOrderGroup: {
    id: number;
    signature: string;
  }[];
  ordersNotConfirmed: {
    id: number;
    signature: string;
  }[];
  ordersAdded: {
    id: number;
    signature: string;
  }[];
  ordersWithExternalTransport?: {
    id: number;
    signature: string;
  }[];
}

export type ExpressServiceType = "A" | "B" | "C";

export interface ExpressService {
  alert15PercentLeft: boolean;
  alert30PercentLeft: boolean;
  alert50PercentLeft: boolean;
  id: UUID;
  name: string;
  type: ExpressServiceType;
  value: number;
}

export interface BulkAssignOrdersToExpressServiceDTO {
  expressServiceId: UUID | null;
  orderIds: number[];
}

export interface CreateExpressServiceDTO {
  name: string;
  value: number;
  type: ExpressService["type"];
}

export interface OrderShippingProgressItem {
  externalServiceStatus: {
    channel: Channels;
    status: ProcessingStatusChoices;
    trackingNumber: string;
  } | null;
  id: number;
  releaseStatus: {
    closedAt: ISODateTime | null;
    createdAt: ISODateTime | null;
    releasedPackages: number;
    status: OrderReleaseStatus;
    totalPackages: number;
  };
  shipments: {
    id: UUID;
    shipmentNumber: string;
    trackingNumber: string;
  }[];
  signature: string;
}

export interface NormalizedOrderShippingProgressItem extends OrderShippingProgressItem {
  isSelected: boolean;
}
